import Vue from 'vue'

export default {

	data: function() {

		return {
			data: {},
			autoload: true,
			is: {
				loading: true,
				initialised: false
			},
			item: {}
		}

	},

	created: function() {

		if (this.autoload) this.load()

		if (this.live) this.$live.$on(this.live + '/update', this.onLiveUpdate.bind(this), this._uid)

	},

	beforeDestroy: function() {

		if (this.live) this.$live.$off(this.changedLive + '/update', this.onLiveUpdate.bind(this), this._uid)

	},

	methods: {

		load: function() {

			this.is.loading = true

			this.$api.get([this.$endpoint(this.endpoint), this.$route.params.id]).then(function(json) {

				Vue.set(this, 'item', this.$util.copy(json.item))

				this.$_.each(this.data, function(value, key) {

					if (json[key]) Vue.set(this.data, key, this.$util.copy(json[key]))

				}.bind(this))

				this.is.loading = false
				this.is.initialised = true

				if (this.afterLoad) this.afterLoad()
				
			}.bind(this))

		},

		onLiveUpdate: function(e) {

			if (this.item.id === e.id) {

				Vue.set(this, 'item', e)

			}

		}

	}

}