<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head :title="item.name" :back="redirect" v-if="is.initialised">

		<template v-if="item.type === $constants.volunteers.role.type.rollcall">

			<app-content-head-actions-item text="Volunteer" icon="volunteers.join" v-on:click="onJoinClick" v-if="data.slots[0].session.can.join" />
			<app-content-head-actions-item text="Cancel" icon="volunteers.leave" v-on:click="onLeaveClick" v-if="data.slots[0].session.can.leave" />

		</template>

	</app-content-head>

	<app-content-alert theme="yellow" v-if="item.eligibility === $constants.volunteers.role.eligibility.review && data.slots[0].session.is.volunteering && !data.slots[0].session.is.confirmed">You have applied for this role. Please wait to receive a notification once reviewed.</app-content-alert>
	<app-content-alert theme="green" v-if="item.eligibility === $constants.volunteers.role.eligibility.review && data.slots[0].session.is.confirmed">You have volunteered and been confirmed for this role.</app-content-alert>
	<app-content-alert theme="blue" v-if="item.eligibility === $constants.volunteers.role.eligibility.review && !data.slots[0].session.is.volunteering && !data.slots[0].session.is.confirmed">This role requires approval to confirm your assignment. You will receive a notification once reviewed.</app-content-alert>

	<app-content-body :is-grid="2" :loading="is.loading && is.initialised" v-if="is.initialised">

		<app-widget-summary :no-icon="true" :headless="true" :span="1" :text="item.description" :metrics="metrics" :image="item.image" />

		<app-widget-chat :no-head="true" :metrics="metrics" :span="1" :id="item.id" />

		<com-slots :span="2" :staticCollection="data.slots" v-if="item.type === $constants.volunteers.role.type.schedule" />

	</app-content-body>

</app-content>

</template>

<script>

import mixItem from '@/mixin/item'

import comSlots from './conventionvolunteersrole/Slots'

export default {

	mixins: [mixItem],

	components: {
		'com-slots': comSlots
	},

	data: function() {

		return {
			data: {
				slots: []
			},
			endpoint: 'convention/volunteers/role',
			live: 'convention/volunteers/role'
		}

	},

	computed: {

		redirect: function() {

			if (this.$route.query.redirect === 'roles') {

				return 'Convention.Volunteers.Roles'

			} else if (this.$route.query.redirect === 'slots') {

				return 'Convention.Volunteers.Slots'

			} else if (this.$route.query.redirect === 'assignments') {

				return 'Convention.Volunteers.Assignments'

			} else {

				return 'Convention.Volunteers.Roles'

			}

		},

		metrics: function() {

			var metrics = []

			if (this.item.type === this.$constants.volunteers.role.type.schedule) {

				metrics.push({
					text: 'Slots',
					value: this.item.count.slots
				})
				
			}

			metrics.push({
				text: 'Capacity',
				value: this.item.count.capacity
			})

			metrics.push({
				text: 'Available',
				value: this.item.count.capacity - this.item.count.filled
			})

			return metrics

		}

	},

	methods: {

		onJoinClick: function() {

			this.$router.push({
				name: 'Convention.Volunteers.Assignment',
				params: {
					id: this.data.slots[0].id
				},
				query: {
					redirect: 'role'
				}
			})

		},

		onLeaveClick: function() {

			this.$router.push({
				name: 'Convention.Volunteers.Assignment',
				params: {
					id: this.data.slots[0].id
				},
				query: {
					redirect: 'role'
				}
			})

		}

	}

}

</script>

<style scoped>

</style>