<template>

<app-content-box :span="span" icon="volunteers.slots" :not-padded="true" title="Slots">

	<app-table :empty="!collection.length" emptyMessage="There are no slots available.">

		<app-table-head :layout="layout">

			<app-table-column text="Day" />
			<app-table-column text="From" />
			<app-table-column text="To" />
			<app-table-column align="center" text="Volunteers" />
			<app-table-column />

		</app-table-head>

		<app-table-body>

			<app-table-row v-on:click="onRoleClick(item.role.id)" :success="item.session.is.volunteering" :layout="layout" v-for="item in collection" :key="item.id">

				<app-table-cell-text :text="item.date.start | formatDate('dddd')" />
				<app-table-cell-text :text="item.date.start | formatDate('h:mma')" />
				<app-table-cell-text :text="item.date.end | formatDate('h:mma')" />
				<app-table-cell-text align="center" :text="item.count.filled.toString() + ' / ' + item.capacity.toString()" />

				<app-table-cell-action icon="volunteers.clash" theme="red" v-tooltip="'Clashing slot'" v-on:click="onJoinClick(item.id)" :large="true" v-if="item.session.can.join && item.session.is.clashing" />
				<app-table-cell-action icon="volunteers.join" theme="white" v-tooltip="'Volunteer for slot'" v-on:click="onJoinClick(item.id)" :large="true" v-if="item.session.can.join && !item.session.is.clashing" />
				<app-table-cell-action icon="volunteers.leave" theme="white" v-tooltip="'Remove volunteer slot'" v-on:click="onLeaveClick(item.id)" :large="true" v-if="item.session.can.leave" />

			</app-table-row>

		</app-table-body>

	</app-table>

</app-content-box>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	props: ['span'],

	data: function() {

		return {
			layout: '120px 120px auto 120px 32px',
			references: {
				days: []
			},
			pagination: false
		}

	},

	methods: {

		onJoinClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignment',
				params: {
					id: id
				},
				query: {
					redirect: 'role'
				}
			})

		},

		onLeaveClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignment',
				params: {
					id: id
				},
				query: {
					redirect: 'role'
				}
			})

		}

	}

}

</script>

<style scoped>

</style>